import { configureStore, combineReducers } from "@reduxjs/toolkit";
import ProjectSlice from "./ProjectSlice";
import UsersSlice from "./UsersSlice";
import UserInvitationSlice from './UserInvitation'
import TimeLogSlice from "./TimeLogSlice";
import SubTaskSlice from "./SubTaskSlice";
import TimeSheetSlice from "./TimeSheetSlice";
import MyPerformanceSlice from "./MyPerformanceSlice";
import ModalSlice from "./ModalSlice";
import CommentsSlice from "./CommentsSlice";
import OrganizationSlice from "./OrganizationSlice";
import LogsSlice from "./LogsSlice";
import TimeApprovalSlice from "./TimeApprovalSlice";
import NotificationsSlice from "./NotificationsSlice";






  
const rootReducer  = combineReducers({
    project: ProjectSlice,
    users: UsersSlice,
    userInvitation: UserInvitationSlice,
    timeLog: TimeLogSlice, 
    subTasks: SubTaskSlice,
    mytimesheet: TimeSheetSlice,
    myPerformace: MyPerformanceSlice,
    modals: ModalSlice,
    comments: CommentsSlice,
    organization: OrganizationSlice,
    logs: LogsSlice,
    timeapproval: TimeApprovalSlice,
    notifications: NotificationsSlice
});


const store = configureStore({
    reducer: rootReducer
});

export default store;